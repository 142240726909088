<script setup lang="ts">
import type { ILeaseDocumentLink } from '@register'
import { useScrollToField } from '@register/components/Review/composables/useScrollToField'

interface Props {
  links: ILeaseDocumentLink[]
}

const props = withDefaults(defineProps<Props>(), {
  links: () => [],
})

const availableLinks = computed(() =>
  props.links.filter((link) => link.hasValue === true),
)

const { clausesAndDefinitions } = useCurrentLeaseDocument()

const activeLink = ref()
function getFieldById(targetId: string) {
  if (activeLink.value?.id === targetId) {
    activeLink.value = undefined
    return undefined
  }

  for (const key in clausesAndDefinitions.value) {
    if (clausesAndDefinitions.value[key] !== undefined) {
      const obj = clausesAndDefinitions.value[key]

      if (obj?.clause?.id === targetId) {
        activeLink.value = obj.clause
        return obj.clause
        //
      } else if (obj?.definition?.id === targetId) {
        activeLink.value = obj.definition
        return obj.definition
      }
    }
  }
  activeLink.value = undefined
  return undefined
}

const { scrollTo } = useScrollToField()
</script>

<template>
  <div
    v-if="availableLinks.length > 0"
    v-auto-animate
    class="col-span-full flex w-full flex-col"
  >
    <span class="text-semibold mb-2 w-full text-sm">
      Clauses and definitions:
    </span>

    <div class="col-span-full flex flex-wrap gap-4">
      <Button
        v-for="link in availableLinks"
        :key="link.id"
        :color="activeLink?.id === link.id ? 'primary' : 'secondary'"
        size="sm"
        @click="getFieldById(link.id)"
      >
        {{ link.name }}
      </Button>
    </div>

    <section
      v-if="activeLink"
      :key="activeLink.id"
      class="bg-gray-750 mr-10 mt-4 rounded-lg p-4"
    >
      <div class="flex justify-between">
        <h4 class="mb-2 font-bold">{{ activeLink.name }}</h4>

        <VTooltip
          class="inline-flex"
          :delay="10"
          placement="top"
          :distance="12"
          :auto-hide="true"
          @click="scrollTo(activeLink.id)"
        >
          <Icon name="document-edit" filled />

          <template #popper>
            <div class="text-xs">Edit</div>
          </template>
        </VTooltip>
      </div>
      <span>
        {{ activeLink?.value?.value ?? 'N/A' }}
      </span>
    </section>
  </div>
</template>
