import { promiseTimeout } from '@vueuse/core'
import { useLeaseSectionTabs } from './useLeaseSectionTabs'

export const useScrollToField = () => {
  const { change: changeTab, scrollTo: scrollToTab } = useLeaseSectionTabs()
  const { lease, specialConditions, getSectionByFieldId } =
    useCurrentLeaseDocument()

  const scrollTo = (id: string) => {
    let sectionId: string | undefined

    const isClauseAndDefinition = lease.value.clausesAndDefinitions.table?.some(
      (field) => field?.id === id,
    )

    // TODO: Integrate special condition with API
    // const isSpecialCondition = lease.value.specialConditions?.some(
    //   (field) => field?.id === id,
    // )
    const isSpecialCondition = false

    if (isClauseAndDefinition) {
      const _id = id.replace('Clause', '').replace('Definition', '')
      sectionId = 'ClausesAndDefinitions-' + _id
    } else if (isSpecialCondition) {
      sectionId = 'SpecialConditions'
    } else {
      sectionId = getSectionByFieldId(id)?.id
    }

    if (!sectionId) return

    changeTab(sectionId)

    promiseTimeout(200).then(() => scrollToTab(sectionId))
  }

  return { scrollTo }
}
